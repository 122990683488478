/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect } from "react";
import { LIST_DOMAIN } from "../../constant/domain";
import { useLocation } from "react-router-dom";

export default function Loading() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const path = searchParams.get("path");
  const isWebviewApp = searchParams.get("isWebviewApp") === "1";
  const pathTelegram = location?.pathname?.split("/telegram/")[1];
  const pathMinigame = window.location.href?.split("/minigame/")[1];

  async function checkDomain() {
    const domainMobile = LIST_DOMAIN.map((domain) => `mobile.${domain}`);
    const domainWebview = LIST_DOMAIN.map((domain) => `webview.${domain}`);
    const domainForApp = [...domainWebview, ...domainMobile].sort(
      () => Math.random() - 0.5
    );
    const domainDefault = LIST_DOMAIN.sort(() => Math.random() - 0.5);

    const domainAll =
      isWebviewApp || pathTelegram
        ? [...domainForApp, ...domainDefault]
        : [...domainDefault];

    const domainAvailable = localStorage.getItem("domainAvailable");

    const domains = domainAvailable 
    ? [domainAvailable,...domainAll]
    : [...domainAll]

    for (const domain of domains) {
      try {
        const response = await axios.head(`https://${domain}/health`);
        if (response.status === 200) {
          localStorage.setItem("domainAvailable", domain);
          setTimeout(() => {
            if (pathTelegram) {
              const search = pathTelegram ? atob(pathTelegram) : null;
              window.location.href = `https://${domain}/autoLogin${search}`
            } else if (pathMinigame) {
              const search = pathMinigame
                ? `?path=${btoa(pathMinigame)}`
                : null;
              window.location.href = `https://${domain}/autoLogin${search}`
            } else if (token || path) {
              window.location.href = `https://${domain}/autoLogin${location.search}`
            } else {
              window.location.href = `https://${domain}`
            }
          }, 3000);
          break;
        }
      } catch (error) {}
    }
  }

  useEffect(() => {
    checkDomain();
  }, []);

  return (
    <div className="flex justify-center">
      <img
        src={`${process.env.PUBLIC_URL}/images/loading/loadingDesktop.jpg`}
        alt=""
        className="min-h-screen w-full object-cover max-sm:hidden"
      />
      <img
        src={`${process.env.PUBLIC_URL}/images/loading/loadingMobile.jpg`}
        alt=""
        className="min-h-screen w-full object-cover sm:hidden"
      />
    </div>
  );
}
